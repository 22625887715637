<template>
  <div>
    <AppImage title="Side Navigation Image" collection="side-navigation-images" :items= sideNavigationImages :lines="0" :singleRecord="true" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import AppImage from "@/components/admin/AppImage";

export default {
  name: "SideNavigation",

  components: {
    AppImage
  },

  computed: {
    ...mapGetters(["sideNavigationImages"])
  }
};
</script>
